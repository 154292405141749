<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAD STOCK OF EQUIPMENT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-layout col>
            <v-flex xs12 md4>
              <v-select
                v-if="type==='Equipment'"
                v-model="equipment"
                class="mx-2"
                dense
                outlined
                label="Equipment"
                :items="equipment_items"
                item-value="equipment_details"
                item-text="equipment_details"
                @change="selected_equipment"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-if="equipment_code_items.length>0"
                v-model="equipment_code"
                class="mx-2"
                dense
                outlined
                label="Code"
                :items="equipment_code_items"
                item-value="equipment_code"
                item-text="equipment_code"
                @change="selected_equipment_code"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-if="equipment_components_items.length>0"
                v-model="equipment_components"
                class="mx-2"
                dense
                outlined
                label="Specify"
                :items="equipment_components_items"
                item-value="equipment_components"
                item-text="equipment_components"
                @change="selected_equipment_components"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-text-field
                v-if="equipment_code_items.length===0"
                class="mx-2"
                v-model="equipment_brand_description"
                label="Code"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="type==='Equipment'"
                class="mx-2"
                v-model="equipment_serial_no"
                label="Serial #"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="type==='Equipment'"
                class="mx-2"
                v-model="equipment_model_color"
                label="Description"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="type==='Equipment'"
                class="mx-2"
                v-model="equipment_date_procure"
                label="Date Procure"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
              <v-text-field
                v-if="type==='Equipment'"
                class="mx-2"
                v-model="equipment_unit_price"
                label="Unit Price"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
              <v-text-field
                v-if="type==='Equipment'"
                class="mx-2"
                v-model="equipment_unit_quantity"
                label="Unit Quantity"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_request"
                  v-if="!saving_data"
                >
                  Save Changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-flex>
            <v-flex xs12 md8>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
                            height="500"
              >
                <template v-slot:item.total="{ item }">
                  <tr>
                    <td>
                      {{ formatPrice(item.equipment_unit_price*item.equipment_unit_quantity) }}
                    </td>
                  </tr>
                </template>
                <template v-slot:item.action="{ item }">
                  <tr>
                    <td class="text-center">
                      <v-icon v-if="!is_deleting" class="mr-2" color="error"
                              @click="delete_stocks(item)">
                        {{ icons.mdiDelete }}
                      </v-icon>
                      <v-progress-circular color="info" indeterminate
                                           v-if="is_deleting"></v-progress-circular>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-form>

    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiDelete} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',
      equipment_unit_quantity: '0',
      equipment_unit_price: '0',
      equipment_model_color: '',
      equipment_serial_no: '',
      eqipment_date_procure: '',
      equipment_brand_description: '',
      equipment_code: '',
      equipment_components: '',
      equipment_date_procure: '',
      equipment: '',
      type: 'Equipment',
      car_brand: '',

      is_deleting: false,

      headers: [
        {text: 'Equipment', value: 'equipment', sortable: true},
        {text: 'Code', value: 'equipment_brand_description', sortable: true},
        {text: 'Serial #', value: 'equipment_serial_no', sortable: true},
        {text: 'Description', value: 'equipment_model_color', sortable: true},
        {text: 'Date Procure', value: 'equipment_date_procure', sortable: true},
        {text: 'Price', value: 'equipment_unit_price', sortable: true},
        {text: 'Quantity', value: 'equipment_unit_quantity', sortable: true},
        {text: 'Total', value: 'total', sortable: true},
        {text: 'Actions', value: 'action', sortable: true},
      ],
      data_items: [],
      equipment_code_items: [],
      equipment_components_items: [],
      equipment_date_procure_items: [],
      equipment_items: [],
      car_brand_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.selected_type('Equipment')
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['initialize_load_stock', 'initialize_load_stock_selected_equipment', 'initialize_load_stock_selected_equipment_code', 'register_stock', 'delete_loaded_stocks']),
      selected_type(value) {
        this.initialize_load_stock({
          type: value,
        })
          .then(response => {
            this.car_brand_items = response.data[0].details
            this.equipment_items = response.data[0].details
            this.data_items = response.data[0].data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_equipment_code(value) {
        this.initialize_load_stock_selected_equipment_code({
          equipment_code: value,
        })
          .then(response => {
            this.equipment_components_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_equipment_components() {

      },
      selected_equipment(value) {
        this.initialize_load_stock_selected_equipment({
          equipment_details: value,
        })
          .then(response => {
            this.equipment_code_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_stocks(item) {
        this.is_deleting = true
        this.delete_loaded_stocks({
          id: item.id,
        })
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              var color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.is_deleting = false
            Object.assign(this.$data, initialState())
            this.selected_type('Equipment')
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('inventory_particulars_id', 1);
          data.append('equipment', this.equipment);
          data.append('equipment_brand_description', this.equipment_code_items.length > 0 ?
            this.equipment_code + (this.equipment_components_items.length > 0 ? ' (' + this.equipment_components + ')' : '')
            : this.equipment_brand_description.toUpperCase());
          data.append('equipment_serial_no', this.equipment_serial_no.toUpperCase());
          data.append('equipment_model_color', this.equipment_model_color.toUpperCase());
          data.append('equipment_date_procure', this.equipment_date_procure);
          data.append('equipment_date_release', this.equipment_date_procure);
          data.append('equipment_unit_price', this.equipment_unit_price);
          data.append('equipment_unit_quantity', this.equipment_unit_quantity);

          data.append('auto_dealer', '');
          data.append('auto_unit', '');
          data.append('auto_mv_file_no', '');
          data.append('auto_plate_no', '');
          data.append('auto_unit_color', '');
          data.append('auto_chasis_no', '');
          data.append('auto_engine_no', '');
          data.append('auto_unit_value', 0);
          data.append('year_model', '0');
          this.register_stock(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.selected_type('Equipment')
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
